<template>
  <div>
    <div v-if="!selectedRole.name">请选择Role</div>
    <div v-else>

      <v-text-field label="角色名称" outlined dense v-model="role.name"></v-text-field>
      <v-text-field label="描述" outlined dense v-model="role.desc"></v-text-field>
      <v-simple-table dense fixed-header class="role-table">
        <thead>
        <tr>
          <th class="text-left table-index"> 菜单</th>
          <th class="text-left"> 页面</th>
          <th class="text-left"> 查看</th>
          <th class="text-left"> 操作</th>
          <th class="text-left"> 导出</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(role,menu_index) in menus">
          <tr v-for="(page, page_index) in role.items" :key="`${menu_index}-${page_index}`">
            <td class=" table-index" v-if="page_index ===0" :rowspan="role.items.length">{{ role.name }}</td>
            <td>{{ page.name }}</td>
            <td>
              <v-simple-checkbox :ripple="false" v-model="page.read"
                                 @input="readChange($event,page)"></v-simple-checkbox>
            </td>
            <td>
              <v-simple-checkbox :ripple="false" v-model="page.edit"
                                 @input="editChange($event,page)"></v-simple-checkbox>
            </td>
            <td>
              <v-simple-checkbox :ripple="false" v-model="page.export"></v-simple-checkbox>
            </td>
          </tr>

        </template>
        </tbody>
      </v-simple-table>

      <v-btn @click="saveRole" class="primary mt-4">
        保存
      </v-btn>
    </div>

  </div>
</template>
<script>
import getMenus from "@/router/menus";
import cloudbase from "@/plugins/cloudbase";


export default {
  name: "roleConfig",
  props: {
    selectedRole: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      menus: this.setMenus(),
      role: {}
    }
  },
  methods: {
    setMenus() {
      const menus = getMenus()
      if (this.role?.permissions?.length > 0) {
        menus.forEach(menu => {
          menu.items = menu.items.reduce((pre, page) => {
            const permission = this.role.permissions.find(s => {
              return s.path === page.path
            })
            if (permission) {
              page.read = permission.read
              page.edit = permission.edit
              page.export = permission.export
            }
            pre.push(page)
            return pre
          }, [])

        })
      }
      return menus
    },
    getPermissions() {
      let permissions = this.menus.reduce((pre, cur) => {
        pre.push(...cur.items.filter(s => {
          return s.read || s.edit
        }))
        return pre
      }, [])
      console.log(permissions)
      return permissions
    },
    editChange(v, page) {
      if (v) {
        this.$set(page, 'read', true)
      }
    },
    readChange(v, page) {
      if (!v) {
        this.$set(page, 'edit', false)
      }
    },
    saveRole() {
      let db = cloudbase.database().collection('role')
      this.role.permissions = this.getPermissions()
      if (this.role._id) {
        db.doc(this.role._id).update({
          name: this.role.name,
          desc: this.role.desc,
          permissions: this.role.permissions
        })
      } else {
        db.add(this.role).then(s => {
          this.role._id = s.id
        })

      }
    }
  },
  watch: {
    'selectedRole'(v) {
      if (v) {
        this.role = {...v}
        this.menus = this.setMenus()
      }
    }
  }
}
</script>

<style scoped>
.role-table {
  border: solid rgba(0, 0, 0, 0.32) 1px;;
}

.table-index {
  border-right: solid rgba(0, 0, 0, 0.12) 1px;
}
</style>