<template>
  <div class="container">
    <div class="nav">
      <v-card min-height="400">
        <div class="white--text primary darken-1 pa-2 text-h6">Role</div>


        <v-navigation-drawer permanent>

          <v-list dense nav>
            <v-list-item-group v-model="selected" color="primary">
              <v-list-item
                  v-for="role in roles"
                  :key="role.name"
                  @click="selectedRole = role"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ role.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-btn class="primary darken-1 mt-2" width="100%" @click="addRole">
              <v-icon class="mr-1">mdi mdi-account-multiple-plus-outline</v-icon>
              添加
            </v-btn>
          </v-list>
        </v-navigation-drawer>

      </v-card>
    </div>
    <div class="role-container elevation-2 rounded">
      <v-tabs v-model="tab">
        <v-tab>权限</v-tab>
        <v-tab>员工</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="pa-4 ma-4">
        <v-tab-item>
          <roleConfig :selected-role="selectedRole"></roleConfig>
        </v-tab-item>
        <v-tab-item>
          <roleUsers></roleUsers>
        </v-tab-item>
        <v-tab-item>
          tab3
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import roleConfig from "@/views/systemSetting/roleManage/roleConfig";
import roleUsers from "@/views/systemSetting/roleManage/roleUsers";
import cloudbase from "@/plugins/cloudbase";

export default {
  name: "roleManage",
  components: {roleConfig, roleUsers},
  data() {
    return {
      tab: '1',
      selected: 0,
      roles: [],
      selectedRole: {}
    }
  },
  created() {
    this.getRoles()
  },
  methods: {
    getRoles() {
      cloudbase.database().collection('role')
          .get()
          .then(({data}) => {
            this.roles = data
          })
    },
    addRole() {
      const role = {
        name: '未命名',
        desc: ''
      }
      this.roles.push(role)
      this.selectedRole = role
    },
  }
}
</script>

<style scoped>

.nav {
  width: 200px;
}

.role-container {
  flex: 1;
  padding: 8px;

}

.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
</style>